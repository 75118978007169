import React from 'react';
import { useSiteMap } from '@unisporkal/sites';
import { useTranslations } from '@unisporkal/localization';
import { Link } from '@unisporkal/alliance-ui-link';
import styles from '../FullFooter.module.scss';

const Services = () => {
  const t = useTranslations();
  const siteMap = useSiteMap();

  return (
    <div data-testid="services">
      <Link
        className={styles.link}
        data-nav="foot_services_mediamanager"
        data-ui-location="footer"
        href={siteMap.mediaManager()}
        target="media_manager"
      >
        {t('media_manager')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_services_rights_and_clearances"
        data-ui-location="footer"
        href={siteMap.rightsAndClearances()}
      >
        {t('rights_and_clearance')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_services_plugins_and_extensions"
        data-ui-location="footer"
        href={siteMap.pluginsAndExtentions()}
        target="_self"
      >
        {t('plugins_and_extensions')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_tools_creative_insights"
        data-ui-location="footer"
        href={siteMap.creativeInsights()}
        target="_self"
      >
        {t('creative_insights')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_tools_visual_gps"
        data-ui-location="footer"
        href={siteMap.visualGPSInsights()}
        target="_self"
      >
        {t('visual_gps')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_Visual_GPS_Insights"
        data-ui-location="footer"
        href={siteMap.visualGpsLanding()}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('visual_gps_insights')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_tools_api_integration"
        data-ui-location="footer"
        href={siteMap.apiIntegration()}
        target="_self"
      >
        {t('api_integration')}
      </Link>
      <Link
        className={styles.link}
        data-nav="foot_search_guide"
        data-ui-location="footer"
        href={siteMap.searchGuide()}
        target="_self"
      >
        {t('search_guide')}
      </Link>
    </div>
  );
};

export default Services;
