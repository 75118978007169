import { QUERY_TYPE } from '../../actions/types';
import useBaseQuery from './useBaseQuery.ts';
import useServiceClient from '../useServiceClient';

const useFooterPropsQuery = () => {
  const { footerDataService } = useServiceClient();

  return useBaseQuery(QUERY_TYPE.FOOTER_DATA, () =>
    footerDataService.fetchFooterData()
  );
};

export default useFooterPropsQuery;
